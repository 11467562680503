import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import cx from 'classnames';

import { GatsbyImage } from 'gatsby-plugin-image';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import { FeatureCard } from '../components/FeatureCard';

import styles from './partners.module.scss';

const PartnersPage = () => {
  const data = useStaticQuery(graphql`
    query MyQuer {
      sponsors: allFile(filter: { relativeDirectory: { eq: "sponsors" } }) {
        nodes {
          name
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  `);

  const sponsors = data.sponsors.nodes;
  sponsors.sort((a, b) => a.name > b.name);

  return (
    <Layout>
      <SEO title="Partners" />
      <section className={cx('content')}>
        <h1>Our Partners</h1>
        <p>
          We partner with incredible organizations on our programs
          to catalyze our shared goal to address and put an end to the.....
          You can learn more about all the organizations we
          partner with below our featured partner.
        </p>
        <section className={cx(styles.cardWrapper)}>
          <FeatureCard
            heading="Community Justice Exchange"
            bannerCopy="Featured Partner"
            isFeatured
          >
            <p>
              GEWS partners with the Ministry of Education - Ghana
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
              Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
              when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
        
            </p>

            <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
            when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
        
            </p>
          </FeatureCard>
        </section>
      </section>
      <section className={cx('content')}>
        <h2>Thanks to our fantastic partners:</h2>
        <br />
        <div>
          <div className={styles.logoGroup}>
            {sponsors.map((logo) => (
              <div key={logo.id} className={styles.logoItem}>
                <GatsbyImage
                  image={logo.childImageSharp.gatsbyImageData}
                  alt={logo.name}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PartnersPage;
